import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

import { usePopper } from 'react-popper';
import styled from "styled-components";

export default function MyRadio(props) {
	const handleChange = (e) => {
		e.persist();
		props.onChange(e.target.value, props.name);
	};
	return (
		<div>
			<FormControl component="fieldset" style={{...props.style}} >
				<RadioGroup style={{ flexDirection: "column"}} color="primary" row={props.row} value={props.value} onChange={handleChange}>
					{
						props.options.map(option=>
							<Option key={option.value} option={option} value={props.value}/>
							// <FormControlLabel color="primary" key={option.id || option.value} value={option.value} control={<Radio color="primary" checked={option.value===props.value} />} label={option.label} />
						)
					}
				</RadioGroup>
			</FormControl>
			
        
		</div>
	);
}



function Option({option, value}) {
	const [referenceElement, setReferenceElement] = React.useState(null);
	const [popperElement, setPopperElement] = React.useState(null);
	const [arrowElement, setArrowElement] = React.useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement:"bottom",
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });
	const [hovered, setHovered] = React.useState(false);

	return <>
		<div className="aaa"  onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} ref={setReferenceElement}>
			<FormControlLabel color="primary" key={option.id || option.value} value={option.value} control={<Radio color="primary" checked={option.value===value} />} label={option.label} />
		</div>
		
		{(hovered && option.info) && <Popper ref={setPopperElement} style={styles.popper} {...attributes.popper}>
			{option?.info}
			<div ref={setArrowElement} style={styles.arrow} />
		</Popper>}
	</>;
	
}
const Popper = styled.div`
	background: #333;
	color: white;
	font-weight: bold;
	padding: 4px 8px;
	font-size: 13px;
	border-radius: 4px;
	white-space:break-spaces;
	max-width:200px;
	z-index:9;
	padding:10px;

`;
MyRadio.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.bool,
	row: PropTypes.bool,
	options: PropTypes.array
};