import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { TextField, Switch, FormLabel } from "@material-ui/core";
import PropTypes from "prop-types";

export default function BoolDetail(props) {
	const handleChange = event => {
		event.persist();
		let a = { ...props.value, value: event.target.checked};
		props.onChange(a, props.name);
	};

	return (
		<>
			<FormControl component="fieldset">
				<FormLabel component="legend">{props.label}</FormLabel>
				<Switch onChange={handleChange} />
				{props.value?.value && props.children}
			</FormControl>
		</>
	);
}

BoolDetail.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
	name: PropTypes.string,
	falseLabel: PropTypes.string,
	trueLabel: PropTypes.string,
	onTrueOptions: PropTypes.array,
	detail: PropTypes.bool,
	showLabel: PropTypes.bool,
	labelInput: PropTypes.string
};