import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";

const MyDropzone = (props) => {
	const onDrop = useCallback(acceptedFiles => {
		// Do something with the files
		props.onFile({files: acceptedFiles}, props.name);
	}, []);
	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
	return (
		<>
			{props.value && props.subtype==="avatar"
				?
				<div>
					<img style={{objectFit:"contain",maxWidth:"100%",maxHeight:"200px"}} src={props.fileUrl+props.value} />
				</div>
				:
				<img style={{objectFit:"contain",maxWidth:"100%",maxHeight:"200px"}} src={props.fileUrl+props.value} />
			}

			<div {...getRootProps()}>
				<input {...getInputProps()} />
				{
					isDragActive ?
						<p>Drop the files here ...</p> :
						<p>Drag n drop some files here, or click to select files</p>
				}
			</div>
		</>
	);
};
export default MyDropzone;

MyDropzone.propTypes = {
	onFile: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	subtype: PropTypes.string,
	fileUrl: PropTypes.string
};