import React from "react";
import Select from "react-select";
import AsyncSelect from 'react-select/async';

import MenuList from "./MenuList";
import Option from "./Option";
import styled from "styled-components";

const StyledSelect  = styled(Select)`
	.react-select__control{
		background:transparent;
		border:0;
	}
	.
`
const filterOptions = (candidate, input) => {
	if (input ) {
		input = input.toLowerCase();
		// console.log(candidate.data)
		return candidate?.data?.searchString?.indexOf(input) > -1;
	}
	return true;
};
const ReactSelect = ({ options, value, onChange, placeholder, name }) => {
	// const change = (data) => {
	// 	console.log("change", data);
	// 	onChange({
	// 		nombre: data.nombre,
	// 		_id: data._id,
	// 		medidas: data.medidas
	// 		// kcal: data.kcal,
	// 		// prot: data.prot,
	// 		// lip: data.lop,
	// 		// hidr: data.hidr,
	// 	});
	// };
	const value2 = React.useMemo(()=>{
	
		return options?.find(d=>d.value===value)||null;
		
		
	},[options, value]);
	

	const handleChange = (value) =>  {
		// console.log({value})
		onChange(value.value, name);
		// if(!value)value=null;
	};

	// const listRef = React.useRef();
	// const sizeMap = React.useRef({});
	// const setSize = React.useCallback((value, size) => {
	// 	const index = options.findIndex(i=>i.value===value);
	// 	// console.log({index,options,value })
	// 	sizeMap.current = { ...sizeMap.current, [index]: size };
	// 	// listRef.current.resetAfterIndex(index);
	// }, []);
	// // const getSize = index => sizeMap.current[index] || 50;
	// const [windowWidth] = useWindowResize();

	// console.log({value2})
	console.log({options})
	return (
		<StyledSelect
			className="bg-blue-light rounded-3xl px-5 w-full"
			// windowWidth={windowWidth}
			// onKeyDown={()=>(console.log("CHANGE"),sizeMap.current=[])}
			// setSize={setSize}
			// listRef={listRef}
			// sizeMap={sizeMap}
			options={options}
			value={value2 && [value2]}
			onChange={handleChange}
			classNamePrefix="react-select"
			filterOption={filterOptions}
			placeholder={placeholder}
			menuPosition="fixed"
			gadMap={[]}
			getOptionLabel={(option) => option.nombre}
			components={{
				MenuList,
				Option,
			}}
		/>
	);
};
const useWindowResize = () => {
	const [size, setSize] = React.useState([0, 0]);

	React.useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}

		window.addEventListener("resize", updateSize);
		updateSize();

		return () => window.removeEventListener("resize", updateSize);
	}, []);

	return size;
};

export default ReactSelect;