import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import Element from "./index";

export default function ModalElement (props) {
	return (
		<div style={{marginBottom:"10px", ...props.style}}>
			<div hidden={props.element.hidden}>
				<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
					<Typography color="secondary" variant="h5" component="h2">
						{props.element.label}
					</Typography>
					{props.element.extraButtons && props.element.extraButtons.map(Ele=>Ele)}
				</div>
				<Element
					{...props}
					style={{marginBottom:"20px"}}
					element={props.element}
					filterBy={props.filterBy}
					value={props.value}
					onChange={props.onChange} />
			</div>
		</div>
	);
}

ModalElement.propTypes = {
	style: PropTypes.object,
	element: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any,
	filterBy: PropTypes.any,
};