import React, {useState} from "react";
import cx from "classnames";
 
const Option = (props) => {
	const {children, isSelected, innerProps, selectProps, value} = props;

	const rowRef = React.useRef();

	// React.useEffect(() => {
	// 	selectProps.setSize(value, rowRef.current.getBoundingClientRect().height);
	// }, );

	return <div
		className={cx("react-select__option", {
			"react-select__option_selected": isSelected,
		})}
		ref={rowRef}

		id={innerProps.id}
		tabIndex={innerProps.tabIndex}
		onClick={innerProps.onClick}
	>
		{children}
	</div>;
};

export default Option;