import React, { useState } from "react";

import {

} from "@material-ui/core";
import { SketchPicker } from "react-color";
import PropTypes from "prop-types";


  
  

const Text = props => {
	const [isOpen, setOpen] = useState(false);
	const hide = () => {
		setOpen(false);

	};
	const show = () => {
		setOpen(true);
	};
	return (
		<>
			<SketchPicker
				onBlur={hide}
				onClick={hide}
				onMouseLeave={hide}
				name='color'
				color={props.value}
				onChangeComplete={color => {
					console.log(color);
					props.onChange(color.hex, props.name);
					// hide();
				}}
			/>
			{/* <button onClick={show} style={{background:props.value, cornerRadius:"20px",height:"40px",width:"70px"}}></button>
			{isOpen && <div onMouseLeave={hide} onBlur={hide}>
				<SketchPicker
					onBlur={hide}
					onClick={hide}
					onMouseLeave={hide}
					name='color'
					color={props.value}
					onChangeComplete={color => {
						console.log(color);
						props.onChange(color.hex, props.name);
						// hide();
					}}
				/>
			</div>
           
			} */}
		</>
	);
    
};

export default Text;

Text.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.string,
	name: PropTypes.string
};