import React, { useEffect } from "react";

import {
	FormGroup,
	FormControl,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import PropTypes from "prop-types";



const MyCheckbox = props => {
	const handleChange = name => event => {
		props.onChange({...props.value, [name.value]:event.target.checked}, props.name);
	};

	useEffect(() => {
		props.options.forEach(val =>{
			if(val.default && props.selectedButton === "new") props.onChange({[val.value]: val.default}, props.name);
		})
	}, []);


	return <FormControl component="fieldset" >
		{/* <FormLabel component="legend">{props.label}</FormLabel> */}
		<FormGroup onChange={(e)=>{
			e.persist();
		}}>
			{props.options.map((o,idx)=>
				<React.Fragment key={o.value}>
					<FormControlLabel 
						control={<Checkbox color="primary" key={o.value+"c"} checked={props.value[o.value]} onChange={handleChange(o)} value={o.value} />}
						label={o.label}
					/>
				</React.Fragment>
			)}
		</FormGroup>
	</FormControl>;


};

export default MyCheckbox;

MyCheckbox.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
	options: PropTypes.array
};