import React,{useRef,useEffect} from "react";
import PropTypes from "prop-types";

import Text from "../Text/Text";
import Checkbox from "../Checkbox/Checkbox";
import Select from "../Select/Select";
import Ref from "../Ref/Ref";
import MultiRef from "../MultiRef/MultiRef";
import Color from "../Color/Color";
import File from "../File/File";
import FileReview from "../FileReview/FileReview";
import Image from "../Image/Image";
import Radio from "../Radio/Radio";
import BoolDetail from "../BoolDetail/BoolDetail";
import DetailCheckbox from "../DetailCheckbox/DetailCheckbox";
import Formula from "../Formula/Formula";
import Group from "../Group/Group";
import AllValues from "../AllValues/AllValues";
import Bool from "../Boolean/Boolean";
import MultiGroup from "../MultiGroup/MultiGroup";
import If from "../If/If";
import CalendarPicker from "../Calendar/Calendar";
import Password from "../Password/Password";
import MultiGroupTabs from "../MultiGroupTabs/MultiGroupTabs";
import Location from "../Location/Location";
import MultiText from "../MultiText/MultiText";
import MultiSelect from "../MultiSelect/MultiSelect";
import StarRating from "../StarRating/StarRating";
import Label from "../Label/Label";
import Select2 from "../Selec/Select";
import Direcciones from "../../Direcciones/Direcciones";
import GAutocomplete from "components/GoogleMaps/Autocomplete/Autocomplete";

// import Equivalencia from "pages/Dietas/DietaEquivalencias/TableEquivalencias/TableEquivalencias";
var shortid = require("shortid");

const Components = {
	text: Text,
	checkbox: Checkbox,
	select: Select,
	ref: Ref,
	multiref: MultiRef,
	color: Color,
	file: File,
	radio: Radio,
	boolDetail: BoolDetail,
	detailCheckbox: DetailCheckbox,
	formula: Formula,
	group: Group,
	allValues: AllValues,
	image:Image,
	multiGroup: MultiGroup,
	bool: Bool,
	if: If,
	calendar: CalendarPicker,
	password: Password,
	multiGroupTabs: MultiGroupTabs,
	location:Location,
	multiText:MultiText,
	multiSelect:MultiSelect,
	starRating: StarRating,
	label:Label,
	fileReview:FileReview,
	select2:Select2,
	gAutocomplete:GAutocomplete,
	autocompleteAddress:GAutocomplete,
	direcciones:Direcciones


};

export default React.memo(function Element (props){
	useEffect(()=>{

		// console.log({default:props.element.default, value:props.value, props})
		if(props.element.default && props.value == null) props.onChange(props.value || props.element.default ,props.element.name);
	},[]);

	const valueOrDefault = (field, value) => {

		switch (field.type) {
		case "text":
			if (!value) return "";
			return value;
		case "radio":
			if (!value) return false;
			return value;
		case "multiGroup":
			if (!value) return [];
			return value.map(v=>({...v,tempid:v.tempid?v.tempid:shortid.generate()}));
		case "boolDetail":
			if (value?.value) return { value: value.value, detail: value.detail };
			return { value: false, detail: "" };
		case "checkbox":
			return field.options.reduce((acc, option) => {
				if (!value || !value[option.value]) return { ...acc, [option.value]: false };
				return { ...acc, [option.value]: value[option.value] };
			}, {});
		case "detailCheckbox":
			return field.options.reduce((acc, option) => {
				if (!value || !value[option.value])
					return { ...acc, [option.value]: { value: false, detail: "" } };
				return {
					...acc,
					[option.value]: {
						value: value[option.value].value,
						detail: value[option.value].detail
					}
				};
			}, {});
		default:
			return value;
		}
	};
	
	return (
		<>
			{React.createElement(Components[props.element.type] || props.element.component, {
				...props.element,
				...props,
				key: props.element.name,
				value: valueOrDefault(props.element, props.value),
				element: props.element,
				onChange: props.onChange,
				name: props.element.name

				// formula:props.element.formula
			})}
      
		</>
	);
});

Element.propTypes = {
	element: PropTypes.object.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func
};