import React from "react";
import Rating from "@material-ui/lab/Rating";
import PropTypes from "prop-types";


const StarRating = props => {
	return (
		<React.Fragment>
			<Rating
				name={props.name}
				value={props.value}
				onChange={(event, newValue) => props.onChange(newValue, props.name)}
			/>
		</React.Fragment>
	);
};

export default StarRating;

StarRating.propTypes = {
	onChange: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};