
import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import useSave from "Hooks/useSave";
import {crudFunctions} from "api";
import {CLIENTE} from "api/TYPES";
import { Button, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {post, get, put, remove, upload, init, revisarCliente} from "api";
import { useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import Joi from "helpers/joi";
const DatosIniciales = ({onClose}) => {

	
	const actions = crudFunctions(CLIENTE);
	const {data:dataCliente} = useQuery(CLIENTE.name, actions.getAll);

	const validation = Joi.object({
		// "legales.ine":Joi.string().required(),
		legales:Joi.object({
			"ine":Joi.any().required(),
			"rfc":Joi.string().required(),
		}).required(),
		direccion:Joi.object({
			"lat":Joi.number().required(),
			"lng":Joi.number().required(),
		}).required(),
		"correo_notif":Joi.string().required()
	});
	const validationEmpresa = Joi.object({
		// "legales.ine":Joi.string().required(),
		// "legales.csf":Joi.string().required(),
		legales:Joi.object({
			"razon_social":Joi.string().required(),
			"csf":Joi.any().required(),
			"ine":Joi.any().required(),
			"rfc":Joi.string().required(),
		}).required(),
		direccion:Joi.object({
			"lat":Joi.number().required(),
			"lng":Joi.number().required(),
		}).required(),
		"correo_notif":Joi.string().required()
	});

	const {data, onChange, onFile, removeFile, remove, save, status,  isValid, setValid} = useSave({
		data:dataCliente,
		crudActions: {...actions, update:revisarCliente},
		updateValidation: dataCliente?.tipo==="fisica" ? validation : validationEmpresa
	});
	const guardar = async () => {
		try{
			const r = await save();
			// console.log("DESPUES", r)
			// onClose();
		}catch(e){
			console.log({e})
		}
		
	};

	

	const elementsPersonaFisica = React.useMemo(()=>[
		{
			type: "text",
			label: "RFC",
			name: "legales.rfc",
		},
		{
			type: "file",
			label: "Ine",
			name: "legales.ine",
			acceptFiles:true

		},
		{
			type: "gAutocomplete",
			name:"direccion",
			label: "Domicilio donde quieren recibir evidencias de entrega",
		},
		{
			type: "text",
			label: "Correo principal para pagos y facturas",
			name: "correo_notif"
		},


		
	],[]);
	const elementsPersonaMoral = React.useMemo(()=>[
		{
			type: "text",
			label: "Razón social",
			name: "legales.razon_social"
		},
		{
			type: "text",
			label: "RFC",
			name: "legales.rfc"
		},
		{
			type: "file",
			label: "Constancia de situación fiscal",
			name: "legales.csf",
			acceptFiles:true
		},
		{
			type: "file",
			label: "Ine representante legal",
			name: "legales.ine",
			acceptFiles:true
		},
		{
			type: "gAutocomplete",
			name:"direccion",
			label: "Domicilio donde quieren recibir evidencias de entrega",
			
		},
		{
			type: "text",
			label: "Correo principal para pagos y facturas",
			name: "correo_notif"
		},


		
	],[]);
	return (
		<div style={{maxWidth:600, margin:"auto", }}>
			<Title>Datos de facturación, pagos y evidencias</Title>
			{/* <H4>Por favor completa la siguiente informaci ón de tu empresa</H4> */}
			<ModalForm hideBox elements={dataCliente?.tipo==="fisica" ? elementsPersonaFisica : elementsPersonaMoral} onChange={onChange} data={data} onFile={onFile} removeFile={removeFile}>
				{status.status==="LOADING" && <CircularProgress />}
				{status.status==="SUCCESS" && <Alert severity="success">Cambios guardados exitosamente</Alert>}
				{status.status==="ERROR" && <Alert severity="error">{status?.errorMsg}</Alert>}
				{status.status!=="LOADING" && <Button variant="contained" color="primary" onClick={guardar}>Guardar</Button>}

			</ModalForm>
		</div>
	);
};
export default DatosIniciales;

const Title = styled.h2`
	margin:20px;
`;
const H4 = styled.h4`
	margin:20px;
`;

// Título: 
// RFC: 
// Adjuntar INE:
// Domicilio donde quieren recibir evidencias de entrega: Calle-Colonia-No interior, Cp, Ciudad
// Uso de las facturas: Gastos en general que salga por default editable
// Correo principal para pagos y facturas:

// PM Luego: Popup ya en la plataforma (si escogió en el inicio de sesión que es cuenta empresarial pedir estos datos)

// Título: Datos de facturación, pagos y evidencias
// Razón social:
// Adjuntar constancia de situación fiscal:
// Adjuntar INE del representante legal:
// Uso de las facturas: Gastos en general que salga por default editable
// Correo principal para pagos y facturas:
// Domicilio donde quieren recibir evidencias de entrega: Calle-Colonia-No interior, Cp, Ciudad
// Texto: ¿Te gustaría pagar tus fletes con crédito? Contáctanos a x correo

// Notify Isaac C. about m
