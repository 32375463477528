import React from "react";
import {useMutation} from "react-query";
import { postAdress, updateAddress, getCustomer } from "api";
import {Button} from "@material-ui/core";
import {CLIENTE} from "api/TYPES";
import styled from "styled-components";
import {Text} from "components/FormComponents";
import Autocomplete from "components/GoogleMaps/Autocomplete/Autocomplete";
import queryClient from "api/query";
import { useQuery } from "react-query";
import _ from 'lodash/fp';


export default function Direcciones({onClose, direccion}){
	const {data:response, mutate} = useMutation(direccion ? updateAddress : postAdress, {onSuccess:(r)=>{
		queryClient.invalidateQueries("cliente");
		onClose(r?.data);
	}});
	const {data:user} = useQuery(CLIENTE.name, getCustomer);

	const [data, setData] = React.useState(direccion || {});
	const onChange = (val, name)=>{
		
		function setBy(obj, path, updater) {
			path = path
				.replace(/\[(\d*)\]/gm, "__int__$1")
				.replace("[", "")
				.replace("]", ".")
				.replace(/\.{2,}/, ".")
				.split(".")
				.map(d => {
					if (d.indexOf("__int__") === 0) {
						return parseInt(d.substring("__int__".length), 10);
					}
					return d;
				});

			function doSet(parent) {
				if (!path.length) {
					return typeof updater === "function" ? updater(parent) : updater;
				}

				const key = path.shift();

				if (typeof key === "string") {
					if (typeof parent === "object") {
						return {
							...parent,
							[key]: doSet(parent[key])
						};
					}
					return {
						[key]: doSet()
					};
				}

				if (typeof key === "number") {
					if (Array.isArray(parent)) {
						const prefix = parent.slice(0, key);
						return [
							...(prefix.length ? prefix : new Array(key)),
							doSet(parent[key]),
							...parent.slice(key + 1)
						];
					}
					return [...new Array(key), doSet()];
				}

				throw new Error("Uh oh!");
			}

			return doSet(obj);
		}


		setData(d=>{
			return setBy(d, name, val);
		});


		// setData(d=>(_.set(d, name, val), d));
		// setData(d=>({...d, [name]: val}));
	};
	const agregar = async () =>{
		const r = await mutate(data);
		
	};
	return <>
		<Row>
			<Td><Label style={{marginTop:0}}>Alias</Label></Td>
			<TdV><Text value={data.alias} onChange={(v)=>onChange(v, "alias")}  /></TdV>
		</Row>
		<Row>
			<Td><Label style={{marginTop:0}}>Dirección</Label></Td>
			<TdV><Autocomplete value={data} onChange={loc=>setData({...data, ...loc})} /></TdV>
		</Row>
		<Row>
			<Td><Label style={{marginTop:0}}>{user?.cliente?.saas ? "Nombre de la empresa": "Núm interior"}</Label></Td>
			<TdV><Text value={data?.direccion?.num_int} onChange={(v)=>onChange(v, "direccion.num_int")}  /></TdV>
		</Row>
		<Row>
			<Td><Label style={{marginTop:0}}>{user?.cliente?.saas ? "Número de rampa" : "Tipo de lugar"}</Label></Td>
			<TdV><Text value={data?.direccion?.tipo_lugar} onChange={(v)=>onChange(v, "direccion.tipo_lugar")}  /></TdV>
		</Row>
		<Row>
			<Td><Label>Nombre de contacto</Label></Td>
			<TdV><Text value={data?.contacto} onChange={(v)=>onChange(v, "contacto")}  /></TdV>
		</Row>
		<Row>
			<Td><Label>Teléfono de contacto</Label></Td>
			<TdV><Text value={data?.telefono} onChange={(v)=>onChange(v, "telefono")}  /></TdV>
		</Row>
		<Button variant="contained" color="primary" onClick={agregar}>{direccion ? "Editar" :"Agregar"}</Button>
	</>;
}

const Row = styled.div``;
const Label = styled.div``;
const Title = styled.div``;
const Value = styled.div``;
const Td = styled.div``;
const TdV = styled.div``;
