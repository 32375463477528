
import React from "react";

import PropTypes from "prop-types";
import {crudFunctions} from "api";
import { useQuery } from "react-query";
import Select from "components/FormComponents/Select/Select";
	

const Ref = (props) => {

	const actions = crudFunctions(props.dataType);

	let {data, status} = useQuery(props.dataType.name, actions.getAll);
	const value = React.useMemo(()=>data?.find(d=>d._id===props.value)?.[props.dataKey],[props.value, data, props.key]);
	// const options = React.useMemo(()=>{
	// 	if(props.extraItems){
	// 		data = [...props.extraItems, ...data];
	// 	}
	// 	return data?.filter(o=> o._id && !o._trash && o.nombre ).sort((rowA, rowB) => {
	// 		let a = rowA.nombre;
	// 		let b = rowB.nombre;
	// 		a = a ? a.toLowerCase() : a;
	// 		b = b ? b.toLowerCase() : b;
	// 		return a === b ? 0 : a > b ? 1 : -1;
	// 	}).map(d=>({label:d.nombre, value:d._id}));
	// },[data]);	

	console.log({props, value, data})
	if(status==="loading")return <>Loading</>;
	return <>{value}</>;
};



export default Ref;

Ref.propTypes = {
	selector: PropTypes.func,
	extraItems: PropTypes.array,
	onChange: PropTypes.func,
	name: PropTypes.string,
	hidden: PropTypes.bool,
	readOnly: PropTypes.bool
};