import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./routes/Routes";
import {socket, crudFunctions} from "api";
import {CLIENTE} from "api/TYPES";
import {useDialog} from "Contexts/DialogContext";
import DatosIniciales from "pages/Login/DatosIniciales";
import {useSocket} from "api/socket";
import { useJsApiLoader, } from '@react-google-maps/api';
import { Loader } from '@googlemaps/js-api-loader';
import useGoogleMapsLoader from "Hooks/useGoogleMapsLoader";
import {useQuery} from "react-query";
import "./App.css";

console.log({openpay: process.env.REACT_APP_OPENPAY_ID, env:process.env.REACT_APP_ENVIRONMENT})
if(window.OpenPay){
	window.OpenPay.setId(process.env.REACT_APP_OPENPAY_ID);
	window.OpenPay.setApiKey(process.env.REACT_APP_OPENPAY_PUBLIC_KEY);
	window.OpenPay.setSandboxMode(process.env.REACT_APP_ENVIRONMENT!=="production");
}
require("analytics");
const libraries = ["places"];

function App() {
	const actions = crudFunctions(CLIENTE);
	const {data:dataCliente, status} = useQuery(CLIENTE.name, actions.getAll);

	const [openDialog] = useDialog();

	useGoogleMapsLoader();
	return (
		
		<Router>
			<Switch>
				<Routes />
			</Switch>
		</Router>
	);
}

export default App;
