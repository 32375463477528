import api, {getCustomer, logout} from "./index";
import socket from "./socket";
import {identify} from "analytics";
import { notificationPermission } from "my_firebase";
import {userStore} from "./store";
import queryClient from "./query";
 
export async function login(token){
	console.log("LOGIN")
	window.localStorage.setItem(process.env.REACT_APP_KEY, token);
	await init();
	// setGlobalState("loggedIn", true);

}
export async function init(){
	
	const token = window.localStorage.getItem(process.env.REACT_APP_KEY);	
	socket.init(token);
	
	if(token){
		api.init(token);
		notificationPermission();

		try{
			console.log("aaa")
			userStore.set(p => ({...p,status:"loading"}));
			const user = await getCustomer();
			userStore.set(p => ({...p, user, loggedIn:true, status:"success"}));
		}catch(e){
			console.error({e})
			logout();
			userStore.set(p => ({...p, status:"error", loggedIn:false}));
		}
		
	}
	else{
		logout();
		userStore.set(p => ({...p, loggedIn:false, status:"success"}));
		
	}
}
export function refresh(key){
	
}



export default {login,init};