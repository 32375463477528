import React from "react";
import {Switch, FormLabel, Checkbox, FormGroup} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

export default function Boolean(props) {
	const handleChange = event => {
		event.persist();
		// props.onChange(event.target.value==="true", props.name);
		props.onChange(event.target.checked, props.name);
	};

	return (
		<>
		{/* <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Assign responsibility</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={gilad} onChange={handleChange} name="gilad" />}
            label="Gilad Gray"
          />
          <FormControlLabel
            control={<Checkbox checked={jason} onChange={handleChange} name="jason" />}
            label="Jason Killian"
          />
          <FormControlLabel
            control={<Checkbox checked={antoine} onChange={handleChange} name="antoine" />}
            label="Antoine Llorca"
          />
        </FormGroup>
        <FormHelperText>Be careful</FormHelperText>
      </FormControl> */}

			<Checkbox
				disableRipple
				color="secondary"
				inputProps={{ 'aria-label': 'decorative checkbox' }}
				checked={props.value || false}
				onChange={handleChange}

			/>

		</>
	);
}

Boolean.propTypes = {
	onChange: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.bool,
	disableOnValue: PropTypes.bool,
	falseLabel: PropTypes.bool,
	trueLabel: PropTypes.bool
};