import React from "react";
// import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import TextField from "components/FormComponents/Text/Text";
import { Icon } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
// Define a default UI for filtering
export default function DefaultColumnFilter(props) {
	const {
		column: { filterValue, setFilter, Header }
	} = props;
	return (
		<TextField
			size="small"
			value={filterValue || ""}
			onChange={e => {
				setFilter(e || undefined); // Set undefined to remove the filter entirely
			}}
			label={`${Header}`}
			variant="outlined"
			color="gray"
			labelAdornment={<SearchIcon />}
			className={`bg-white border border-gray-light rounded px-5 py-2 w-full placeholder-gray-light ${props.className}`}
		/>
	);
}

DefaultColumnFilter.propTypes = {
	column: PropTypes.object
};