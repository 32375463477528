import queryClient from "./query";
import {setGlobalState} from "./store";
const axios = require("axios");
const token = window.localStorage.getItem(process.env.REACT_APP_KEY);
// export const socket = io.connect("http://localhost:3000/consultas_online");
 


// export const socket = io("http://localhost:3000/consultas_online");

let instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 120000,
});
instance.interceptors.request.use(
	config=>{
		const t = window.localStorage.getItem(process.env.REACT_APP_KEY);
		config.headers.Authorization = `Bearer ${t}`;
		return config;

	}
)
export function init(token){
	instance.defaults.headers["Authorization"] = `Bearer ${token}`;
}

export const Authorization = `Bearer ${token}`;
export function get(uri, params){
	return new Promise((resolve, reject)=>{
		instance.get(uri,{params}).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function externalGet(uri){
	return new Promise((resolve, reject)=>{
		axios.get(uri).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}

export function post(uri, data, options){
	return new Promise((resolve, reject)=>{
		instance.post(uri, data, options).then(function (response) {
			resolve(response.data, response.headers);
		}).catch(error=>{
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export async function download(uri, data, options, filename){
	
	return new Promise((resolve, reject)=>{
		instance.post(uri, data, options).then(function (response) {
		

			const blob = new Blob([response.data]);
			const link = document.createElement("a")
			link.href = URL.createObjectURL(blob)
			link.download = filename;
			link.click();
			setTimeout(() => URL.revokeObjectURL(link.href), 0)

			resolve(response.data);
		}).catch(error=>{
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});

	
}
export function put(uri, data){
	return new Promise((resolve, reject)=>{
		instance.put(uri, data).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function remove(uri, data){
	return new Promise((resolve, reject)=>{
		instance.delete(uri, data).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function upload(uri, files){
	return new Promise((resolve, reject)=>{
		// const url = 'http://localhost:3000/patients/img';
		const formData = new FormData();
		if(Array.isArray(files)){
			files.forEach(f => {
				formData.append(f.name,f.file);
			});
		}
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			},
			timeout:180000
		};
		instance.post(uri, formData, config).then(function (response) {
			resolve(response.data);
		}).catch(function (error){
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject(error.response?.data.error || error.message);
		});
	}); 
}
export const track = console.log
export const login = (data) => post("/cliente-login",data, { withCredentials: true });
export const logout = () => {
	console.log("LOGGING OUT")

	// setGlobalState("loggedIn",false)
	instance.defaults.headers["Authorization"] = null;

	queryClient.resetQueries()

	queryClient.removeQueries();
	queryClient.invalidateQueries();
	return post("/cliente-logout");
}
export const postAdress = (data) => post("/clientes/me/direccion",data);
export const updateAddress = (data) => put(`/clientes/me/direccion/${data._id}`,data);

export const revisarCliente = async (id, {files, ...data}) => {
	let r = await post("/clientes/me/revisar", data);
	if(files){
		r = await upload("/clientes/me/files", files);
	}
	return r;
};
export const continuarViaje = (id_viaje, nombre) => post("/continuar-viaje",{id_viaje, nombre});
export const getHome = () => get("/clientes/me/home");
export const getCartaPorte = () => axios.get("/carta_porte.json");
export const getCustomer =(id) => get("/clientes/me");
export const getUsers =() => get(`/clientes/me/usuarios/`);
export const getUser =({queryKey}) => get(`/clientes/me/usuarios/${queryKey[1]}`);
export const updateUser = async ({id, data}) => { 
	await put(`/clientes/me/usuarios/${id}`, data);
	queryClient.invalidateQueries();
}
export const updateCliente = async (data) => {
	await put(`/clientes/me`, data);
	queryClient.invalidateQueries();
}

export const getEmpresas =() => get(`/clientes/me/empresas/`);
export const getEmpresa =({queryKey}) => get(`/clientes/me/empresas/${queryKey[1]}`);
export const updateEmpresa = ({id, data}) => put(`/clientes/me/empresas/${id}`, data);
export const postEmpresa = (data) => post(`/clientes/me/empresas`, data);
export const postInsuranceLead = (data) => post(`/seguros-lead`, data);


export const exportarViajes = (data) => download("/viajes-dedicados/exportar",data, { withCredentials: true }, "viajes.csv");
	// const blob = new Blob([response]);
	// const link = document.createElement("a")
	// link.href = URL.createObjectURL(blob)
	// link.download = 'customers.csv'
	// link.click()
	// setTimeout(() => URL.revokeObjectURL(link.href), 0)

export const registro = (data) => post("/cliente-registro",data, { withCredentials: true });
export const asignarPassword = (data) => post("/cliente-set-password",data, { withCredentials: true });

export const getTiposUnidad = () => get("/tipos-unidad");
export const getTiposMercancia = () => get("/tipos-mercancia");
export const getViajes = (data) => get("/clientes/me/viajes");
export const getConsolidado = (id) => get(`/clientes/me/viajes-consolidados/${id}`);
export const getDedicado = (id) => get(`/clientes/me/viajes-dedicados/${id}`);
export const cancelarViaje = (id) => post(`/clientes/me/viajes/${id}/cancelar`);
export const getViajePublico = ({queryKey}) => get(`/viaje/${queryKey[1]}`);
export const getViaje = ({queryKey}) => get(`/clientes/me/viajes/${queryKey[1]}`);
export const getViajeByID = (id) => get(`/clientes/me/viajes/${id}`);
export const getViajesActivos = () => get("/clientes/me/tracking");
export const realizarPago = (data) => post(`/pagos-cliente/${data._id}/pagar`, data);
export const reportarIncidencias = (idViaje, incidencias) => post(`/clientes/me/viajes/${idViaje}/incidencias`,incidencias);
export const solicitarFacturaSeguro = (idSeguro) => post(`/clientes/me/seguros/${idSeguro}/facturar`);

export const postConsolidado = (data) => post("/viajes-consolidados",data);
export const postProyecto = (data) => post("/proyectos",data);
export const postCustomerCard = ({token, sessionId}) => post("/clientes/me/cards", {token, sessionId});
export const getCustomerCards = () => get("/clientes/me/cards");
export const pagarViajeDedicado = ({idViaje, cardId, sessionId}) => post(`/viajes-dedicados/${idViaje}`, {cardId, sessionId});
export const finalizarViajeConsolidado = (idViaje) => post(`/viajes-consolidados/${idViaje}/finalizar-pago`);
export const finalizarViajeDedicado = (idViaje) => post(`/viajes-dedicados/${idViaje}/finalizar-pago`);
export const finalizarPago = (id, id_cliente) => post(`/pagos-cliente/${id}/finalizar-pago`,{id_cliente});
export const cotizarViajeDedicado = (data) => post("/cotizar", data);
export const cotizarViajeConsolidado = (data) => post("/cotizar-consolidado", data);
export const cotizarSeguro = (data) => post("/seguros/cotizar", data);
export const crearSeguro = (data) => post("/seguros", data);
export const finalizarSeguro = (idSeguro, id_cliente) => {console.log({id_cliente});return post(`/seguros/${idSeguro}/finalizar-pago`,{id_cliente});}
export const reportError = (data) => post("/errores",data);


export const getTrygerLocations = (cp) => externalGet(`${process.env.REACT_APP_TRYGER_ENDPOINT}/chubb/location?zipCode=${cp}`);
export const getTrygerAssets = () => externalGet(`${process.env.REACT_APP_TRYGER_ENDPOINT}/assets`);

export const postPrecio = (data) => post("/clientes/me/precios-saas", data);

export const postDedicado = async ({files, ...data}) => {
	const response = await post("/viajes-dedicados",data);
	if(files){
		await upload(`/viajes-dedicados/${response.viaje._id}/files`, files);

		return response;
	}
	return response;
};

export const crudFunctions = (type, {replacements, parent_id, baseUrl}={}) => {
	const tipo = type.url;
	const tail = type.tail_url;
	const url = tail ? `${tipo}/${parent_id}/${tail}` : `${tipo}`;

	return {
		getAll: ({queryKey})=>{
			if(queryKey.includes("ref")) return get(`/${url}/?all=true`);
			return get(`/${url}`);
		},
		getOne: (id)=>{
			return get(`/${url}/${id}`);
		},
		getPaginated: ({queryKey})=>{
			return get(`/${tipo}`,{page:queryKey[1].page, search:queryKey[1].filter, sort:queryKey[1].sort});
		},
		getOneQueryKey: ({queryKey})=>{
			return get(`/${url}/${queryKey[1]}`);
		},
		insert:async ({files, ...data})=>{
			const response = await post(`/${url}`, data);
			if(files){
				const r = await upload(`/${url}/${response.data._id}/files`, files);
				return r;
			}
		},
		update:async (id, {files, ...data})=>{
			await put(`/${url}/${id}`, data);
			if(files){
				const r = await upload(`/${url}/${id}/files`, files);
				return r;
			}
		},
		trash:(id)=>{
			return put(`/${url}/${id}`, {_trash: true});
		},
		untrash:(id)=>{
			return put(`/${url}/${id}`, {_trash: false});
		},
		delete:(id)=>{
			return remove(`/${url}/${id}`);
		},
		...replacements
	};
};


export default {post, get, put, remove, upload, init, externalGet};
