import React from "react";
import PropTypes from "prop-types";

export default function Formula(props) {
	return (<>
		{eval(props.element.formula)(...props.value)}
	</>);
};

Formula.propTypes = {
	element: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};