import React from "react";
import Element from "../Element";
import PropTypes from "prop-types";

import { Card, Grid, Typography } from "@material-ui/core";
import _ from "lodash"
const Group = props => {
	return (
		<Card className={props.className}>
			<div style={props.horizontal ? {padding: "20px", display: "flex", flexDirection:"row", alignItems:"center"} : { padding: "20px" }}>
				<Typography variant="h5" component="h2">{props.title}</Typography>

				{props.element.fields.map(field => (
					<div key={field.id || field.name} style={{marginBottom:"8px"}}  >
						<div style={{display: "flex", alignItems: "center"}}>
							<div>
								<Typography style={{fontWeight:"bold"}}>{field.label}</Typography>
								<Typography>{field.subLabel}</Typography>
							</div>

							{field.buttons && field.buttons.map((Ele,idx) => (
								<div key={idx} style={{marginLeft: "5px"}}>
									{Ele}
								</div>
							)) 
							}
						</div>
						<Element
							element={field}
							value={field.name ? _.get(props.value, field.name) : props.value }
							onChange={props.onChange}
							onFile={props.onFile}
							removeFile={props.removeFile}
							files={props.files}
						></Element>
					</div>
				))}
			</div>
		</Card>
	);
};
export default Group;

Group.propTypes = {
	className: PropTypes.string,
	horizontal: PropTypes.bool,
	element: PropTypes.object,
	value: PropTypes.object,
	onChange: PropTypes.func
};