import React from "react";
import PropTypes from "prop-types";

export default function BaseCell({children, style, className}){
	return (
		<>
			<div className={`text-blue font-bold ${className}`} style={{...style}}>{children}</div>
		</>
	);
}

BaseCell.propTypes = {
	value: PropTypes.string
};