import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";


import RefreshButton from "./RefreshButton";
import CerrarSesionButton from "./CerrarSesionButton";

import { useWindowSize } from "Hooks";


import "./Header.scss";

const useStyles = makeStyles({
	colorPrimary: {
		color: "white",
	},
});

const Header = props => {


	const {innerWidth} = useWindowSize();

	return (
		<>
			<div className="container-header" style={{display: "flex", alignItems: "center"}}>
				<div className="left">
					{props.title && <h2 className="title">{props.title}</h2>}
					
				</div>
				{innerWidth > 769 &&
						
        <div className="right botones">
        	
        	<RefreshButton />
        	<CerrarSesionButton />
        </div>
				}
			</div>
		</>
	);
};

export default Header;

Header.propTypes = {
	title: PropTypes.string,
	contratarPlan: PropTypes.bool
	
};