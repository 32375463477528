import React,{useState} from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import {Input, Button} from "@material-ui/core";

const EMPTY_FUNC = () => {};
const DialogContext = React.createContext([
	EMPTY_FUNC,
	EMPTY_FUNC
]);
export const useDialog = () => React.useContext(DialogContext);

function DialogContainer(props) {
	const { content, Content, title, actions, open, onClose, onKill, width, disableBackdropClick } = props;
	const [data, setData] = useState();
	const close = (a, reason) => {
		if(!disableBackdropClick){
			return onClose();
		}
	};
	return (
		<Dialog fullWidth={width ? false: true}	maxWidth={width} open={open} onClose={close} onExited={onKill}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{/* {content==="input" && <Input onChange={e=>setData(e.target.value)} />} */}
				{typeof content==="string" && <div>{content}</div> }
				{Content && <Content {...props} />}
				
			</DialogContent>
			<DialogActions>
				{(actions || []).map(a=><Button key={a.label} onClick={()=>(a.onClick(data))}>{a.label}</Button>)}
			</DialogActions>
		</Dialog>
	);
}

export default function DialogProvider({ children }) {
	const [dialogs, setDialogs] = React.useState([]);
	const createDialog = (option) => {
		const dialog = { ...option, open: true };
		setDialogs((dialogs) =>{
			let r= [...dialogs, dialog]
			console.log({open:r})
			return r;
		});
	};
	const closeDialog = (...data) => {
		setDialogs((dialogs) => {
			const latestDialog = dialogs.pop();
			if (!latestDialog) return dialogs;
			if (latestDialog.onClose) latestDialog.onClose(...data);

			let r = [...dialogs].concat({ ...latestDialog, open: false });
			console.log({close:r, dialogs});
			return r
		});
	};
	const contextValue = React.useRef([createDialog, closeDialog]);

	React.useEffect(()=>{
		console.log({dialogs})
	},[dialogs]);
	return (
		<DialogContext.Provider value={contextValue.current}>
			{children}
			{dialogs.map((dialog, i) => {
				const { onClose, ...dialogParams } = dialog;
				const handleKill = (kill) => {
					console.log("kill", kill)
					if (dialog.onExited) dialog.onExited();
					setDialogs((dialogs) => {
						// console.log({dialogs});
						return dialogs.filter(d=>d.open);
						// return dialogs.slice(0, dialogs.length - 1)
					
					});
				};

				return (
					<DialogContainer
						key={i}
						onClose={closeDialog}
						onKill={handleKill}
						{...dialogParams}
					/>
				);
			})}
		</DialogContext.Provider>
	);
}
