import React from "react";
import AllValues from "../AllValues/AllValues";
import { TextField, InputAdornment, Button, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { twMerge } from "tailwind-merge";

import './Text.scss';
import PropTypes from "prop-types";
import styled from "styled-components";
// const StyledTextField = styled(TextField)`
// 	fieldset{
// 		border-color:#818181;
// 	}
// 	input::placeholder{
// 		font-size:10px;
// 		align-self:center;
// 	}
// `;

function useTraceUpdate(props) {
	const prev = React.useRef(props);
	React.showLabeluseEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			console.log("Changed props:", changedProps);
		}
		prev.current = props;
	});
}
const Text = props => {
	return (
		<React.Fragment>
			{props.element?.showHistoric && (
				<AllValues value={props.value} element={props.element} />
			)}
			{/* {props.labelInput} */}
			<input
				className={twMerge(`bg-blue-light rounded-3xl px-5 py-2 w-full ${props.className}`)}
				size="small"
				variant="outlined"
				disabled={props.readOnly}
				multiline={props.multiline || props.moreRows }
				rows= {props.moreRows && 5}
				label={props.showLabel ? props.labelInput : ""}
				value={props.formula ? String(props.formula(props.value)) : props.value}
				type={props.subtype ? props.subtype : "text"}
				onChange={event => {
					let v = props.subtype==="number" ? +event.target.value : event.target.value;
					props.onChange(v, props.name);
				}}
				placeholder={props.label}
				InputProps={{
					startAdornment: props.labelAdornment ? <InputAdornment position="start">{props.labelAdornment}</InputAdornment> : undefined,
					endAdornment: props.subtype === "email" ? <a className="end-adornment" href={`mailto:${props.value}`}><Icon className="icon">send_icon</Icon></a> : props.subtype === "phone" && <a className="end-adornment" href={`tel:${props.value}`}><Icon className="icon">phone_icon</Icon></a>
				}}
				// inputProps={{
				//   style:{padding:10}
				// }}
				// inputMultiline={{
				//   style:{padding:0}
				// }}
			/>
		</React.Fragment>
	);
};

export default React.memo(Text);

Text.propTypes = {
	element: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	readOnly: PropTypes.bool,
	multiline: PropTypes.bool,
	moreRows: PropTypes.bool,
	showLabel: PropTypes.bool,
	labelInput: PropTypes.string,
	formula: PropTypes.func,
	subtype: PropTypes.string,
	labelAdornment: PropTypes.string
};