import React from "react";
import { TextField, Button } from "@material-ui/core";

const Password = props => {
	const [showPassword, setShowPassword] = React.useState(false);
	const [password, setPassword] = React.useState(props.value);
	const [confirmPassword, setConfirmPassword] = React.useState();
	const [changing, setChanging] = React.useState(false);


	React.useEffect(()=>{
		if(props.confirmPassword){
			if(password===confirmPassword) {
				setChanging(false);
				props.onChange(password, props.name);
				props.setValid && props.setValid(true);
			}
			else{
				props.setValid && props.setValid(false);
			}
		}else{
			if(changing){
				props.onChange(password, props.name);
				props.setValid && props.setValid(true);
			}
			
		}
		
	},[password, confirmPassword]);


	return (
		<div>
			{(props.value&&!changing)
				?
				<div>
					<div>Contraseña asignada</div>
					<Button variant="contained" color="secondary" onClick={()=>setChanging(c=>!c)}>Cambiar</Button>
				</div>
				:
				<>
					<TextField
						label="Contraseña"
						variant="outlined"
						// value={password||""}
						id="standard-adornment-password"
						type={showPassword ? "text" : "password"}
						style={{
							margin:"10px 0"
						}}
						onChange={event => {
							setChanging(true);
							setPassword(event.target.value);

						}}
					/>
					{props.confirmPassword && 
						<TextField
							label="Confirmar Contraseña"
							variant="outlined"
							id="standard-adornment-password"
							type={showPassword ? "text" : "password"}
							// value={confirmPassword}
							error={password === confirmPassword ? false : true}
							onChange={event => {
								setConfirmPassword(event.target.value);
							}}
						/>
					}
				</>
			}
		</div>
	);
};

export default Password;
