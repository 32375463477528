import React from "react";
import {useQuery} from "react-query";
import {getCustomer} from "api";
import {CLIENTE} from "api/TYPES";
import {Button, IconButton} from "@material-ui/core";
import styled from "styled-components";
import {useDialog} from "Contexts/DialogContext";
import AgregarDireccion from "./AgregarDireccion";
import EditIcon from '@material-ui/icons/Edit';
export default function DireccionesModal({onSelect, onClose, onChange, textSize="text-sm"}){
	const [openDialog] = useDialog();
	const open = () => {
		openDialog({
			Content: Direcciones,
			onSelect,
			onChange,
			onClose
		});
	};
	return <>
		<button className={`text-orange underline ${textSize}`} onClick={open}>Mis direcciones</button>
	</>;
}
function Direcciones({onSelect, onClose, onChange}){
	const {data} = useQuery("cliente", getCustomer);
	const [openDialog] = useDialog();
	const agregarDireccion = () => {
		openDialog({
			Content: AgregarDireccion
		});
	};

	return <>
		<button className="rounded-3xl text-white bg-orange py-2 px-6" onClick={agregarDireccion}>Agregar direccion</button>
		{data?.cliente?.direcciones?.map(direccion=><Direccion key={direccion._id} onSelect={onSelect} onChange={onChange} direccion={direccion} onClose={onClose} />)}
	</>;
}
function Direccion({direccion, onSelect, onChange, onClose}){
	const [openDialog] = useDialog();

	const edit = () => {
		openDialog({
			Content: AgregarDireccion,
			direccion
		});
	};
	return <>
		<Row>
			<IconButton color="primary" onClick={edit} component="span">
      	<EditIcon />
      </IconButton>
			<Title onClick={()=>(onSelect ? onSelect(direccion): onChange(direccion),onClose())}>{direccion?.alias}</Title>

		</Row>
	</>;
}
const Row = styled.div`
	width:100%;
	border-bottom: 1px solid var(--colors-primary)
`;
const Label = styled.div``;
const Title = styled.span``;
const Value = styled.div``;
