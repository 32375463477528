import React from "react";
import PropTypes from "prop-types";
import { Card, Typography } from "@material-ui/core";
// import BotonVerApp2 from "assets/img/app-paciente/vista-metas.png";
import "./Box.scss"
// import InfoTooltip from "components/Modals/Chart";

export default function Box(props) {
	return (
		<>
			<Card
				variant="outlined"
				padding="20px"
				className={props.className}
				style={{
					height: "100%",
					background: "#F6F6F6",
					padding: "20px",
					margin:props.fullWidth?"unset":"auto",
					...props.style
				}}>
				<div style={{display:"flex", justifyContent:"space-between", alignItems: "center", height: (props?.subLabel || props?.extraButtons) && "40px", marginBottom:(props?.subLabel || props?.extraButtons) && "5px"}}>
					<div>

						<Typography style={{minHeight:"15px"}} variant="h5">{props.index} {props.label}</Typography>
						<Typography variant="subtitle1">
							{props.sublabel}
						</Typography>
					</div>
					<div className="extra-buttons-box">
						{props.extraButtons && props.extraButtons.map(Ele => Ele)}
					</div>
				</div>
				{!props.hideCard && <Card
					variant="outlined"
					padding="20px"
					style={{ height: "calc(100% - 30px)", padding: "20px", width:"100%"}}>
					{props.children}
				</Card>}
			</Card>
		</>
	);
}
Box.propTypes = {
	fullWidth: PropTypes.bool,
	label: PropTypes.string,
	subLabel: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};