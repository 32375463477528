import React from "react";
import firebase from "firebase/app";
import "@firebase/messaging";
import swal from "@sweetalert/with-react";
import DefaultSwal from "./components/Modals/DefaultSwal";
import {put, socket} from "api";
import {createBrowserHistory} from 'history'; 

const config = {
	messagingSenderId: "807917655924"
};

var firebaseConfig = {
	apiKey: "AIzaSyAMxhbE9ELaIGrUkhHHT8N_4zA_gm9xMe8",
	authDomain: "nuapp-38282.firebaseapp.com",
	databaseURL: "https://nuapp-38282.firebaseio.com",
	projectId: "nuapp-38282",
	storageBucket: "nuapp-38282.appspot.com",
	messagingSenderId: "807917655924",
	appId: "1:807917655924:web:e322029cdbbc64c1897e21"
};
firebase.initializeApp(firebaseConfig);

// firebase.initializeApp(config);

let messaging;

// we need to check if messaging is supported by the browser
if (firebase.messaging.isSupported()) {
	messaging = firebase.messaging();

	messaging.onMessage(payload => {
		console.log(payload);
		received(payload.data);
	});
}

// messaging.useServiceWorker({})

async function notificationPermission() {
	let permissionGranted = false;
	try {
		/* request permission if not granted */
		if (Notification.permission !== "granted") {
			await messaging.requestPermission();
		}
		const token = await messaging.getToken(); // returns the same token on every invocation until refreshed by browser
		console.log(token);
		/* get instance token if not available */
		if (localStorage.getItem("INSTANCE_TOKEN") !== null) {
			permissionGranted = true;
		} else {
			const token = await messaging.getToken(); // returns the same token on every invocation until refreshed by browser
			console.log(token);
			localStorage.setItem("INSTANCE_TOKEN", token);
			permissionGranted = true;

		}
	} catch (err) {
		console.log(err);
		if (
			err.hasOwnProperty("code") &&
      err.code === "messaging/permission-default"
		)
			console.log("You need to allow the site to send notifications");
		else if (
			err.hasOwnProperty("code") &&
      err.code === "messaging/permission-blocked"
		)
			console.log(
				"Currently, the site is blocked from sending notifications. Please unblock the same in your browser settings"
			);
		else console.log("Unable to subscribe you to notifications");
	} finally {
		return permissionGranted;
	}
}
function received(data) {
	console.log({window});
	if(!window.alreadyClicked){
		window.alreadyClicked = true;
		swal({
			closeOnClickOutside: false,
			timer: 30000,
			buttons:false,
			content:<DefaultSwal 
				tipo="success" 
				titulo="Nueva Consulta Online" 
				subtitulo="Un paciente nuevo está en busca de una consulta online. Haz click en aceptar para conectar con el paciente." 
				actions={[{titulo:"Aceptar", tipo:"fn", to:"/consultaonline", fn:()=>{
					window.location.href = `/salaespera/consulta/${data._id}`;
				}}]} 
			/>
		});
	}

}

export default messaging;
export { notificationPermission };
