// export let CONDUCTOR = {url:"conductores", name:"conductor", label:"Conductor"};
// export let UNIDAD = {url:"unidades",name:"Unidad", label:"Unidad"};
export let TIPO_UNIDAD = {url:"tipos-unidad",name:"tipo_unidad", label:"Tipo de unidad"};
export let VIAJE = {url:"clientes/me/viajes",name:"viaje", label:"Viaje"};
export let PAGO = {url:"clientes/me/pagos",name:"pago", label:"Pago"};
export let CLIENTE = {url:"clientes/me",name:"cliente", label:"Cliente"};
export let USUARIO = {url:"clientes/me/usuarios",name:"usuario", label:"Usuario"};
export let EMPRESA = {url:"clientes/me/empresas",name:"empresa", label:"Empresa"};
export let SEGURO = {url:"clientes/me/seguros",name:"seguro", label:"Seguro"};
// export let SOLICITUD = {url:"solicitudes",name:"solicitud", label:"Solicitud"};
export const MERCANCIA = {url:"tipos-mercancias",name:"mercancia", label:"Mercancia"};
export const PRECIO_SAAS = {url:"clientes/me/precios-saas",name:"precios-saas", label:"Precio"};
export const TRANSPORTISTA = {url:"clientes/me/transportistas",name:"transportistas", label:"Transportista"};
export const UNIDAD_TRANSPORTISTA = {url:"clientes/me/transportistas", tail_url:"unidades",name:"unidad_transportista", label:"Unidad"};
export const CONDUCTOR_TRANSPORTISTA = {url:"clientes/me/transportistas", tail_url:"conductores",name:"conductor_transportista", label:"Conductor"};
export const USUARIO_TRANSPORTISTA = {url:"clientes/me/transportistas", tail_url:"usuarios",name:"usuario_transportista", label:"Usuario"};