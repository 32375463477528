import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import React, {useRef, useState, useEffect} from "react";
import "./Autocomplete.css";
import LoadScriptWhenNeeded from "components/GoogleMaps/LoadScriptWhenNeeded";
import styled from "styled-components";
import {Alert} from "@material-ui/lab";
import {Button} from "@material-ui/core";
import useGoogleMapsLoader from "Hooks/useGoogleMapsLoader";
import {Address} from "components/Core";

function useClientRect() {
	const [rect, setRect] = useState(null);
	const ref = React.useCallback(node => {
		if (node !== null) {
			setRect(node);
		}
	}, []);
	return [rect, ref];
}

export default function Autocomplete({onChange, value, name, readOnly, label}) {
	// const [status] = useGoogleMapsLoader();

	const autocomplete = useRef();
	const [validLocation, setValidLocation] = useState(false);
	const [location, setLocation] = useState(null);

	const [formatted, setFormatted] = React.useState(value);
	React.useEffect(()=>{
		setFormatted(value);
	},[value]);
	
	const onLoad  = (a) => {
		autocomplete.current = a;
	};
	const getLocation = (location, components, nombre) => {
		const direccion = components.reduce((acc, component)=>{
			if(component.types.includes("administrative_area_level_1")){
				return {...acc, estado: component.long_name};
			}
			if(component.types.includes("country")){
				return {...acc, pais: component.long_name};
			}
			if(component.types.includes("postal_code")){
				return {...acc, cp: component.long_name};
			}
			if(component.types.includes("route")){
				return {...acc, calle: component.long_name};
			}
			if(component.types.includes("street_number")){
				return {...acc, numero: component.long_name};
			}
			if(component.types.includes("sublocality_level_1")){
				return {...acc, colonia: component.long_name};
			}
			if(component.types.includes("locality")){
				return {...acc, ciudad: component.long_name};
			}
			return acc;
			
		},{});
		console.log({nombre});
		return {...location, direccion: {...direccion, nombre_lugar:nombre} };
	};
	
	useEffect(()=>{

			// console.log({location})
		// const validateLocation = location => location && location.direccion && location.direccion.estado && location.direccion.pais && location.direccion.cp && location.direccion.calle && location.direccion.numero && location.direccion.colonia;
		setValidLocation(location);
	},[location]);

	useEffect(()=>{
		setChanging(false);
		// if(!validLocation) onChange(null);
		if(validLocation) onChange(location, name);
	},[validLocation]);

	const onPlaceChanged = () => {
		if (autocomplete.current !== null) {
			const direccion = getLocation({
				lat: autocomplete.current.getPlaces()[0].geometry.location.lat(), 
				lng:autocomplete.current.getPlaces()[0].geometry.location.lng(),
			}, autocomplete.current.getPlaces()[0].address_components, autocomplete.current.getPlaces()[0].name );
			setLocation({...direccion, formatted:autocomplete.current.getPlaces()[0].formatted_address});
			
		} else {
			console.log("Autocomplete is not loaded yet!")
		}
	};
	const [input, inputRef] = useClientRect();
	useEffect(()=>{
		if (window.MutationObserver && input) {
			var observerHack = new MutationObserver(function () {
				observerHack.disconnect();
				input.autocomplete = 'disable-autofill';
			});
			observerHack.observe(input, {
				attributes: true,
				attributeFilter: ['autocomplete']
			});
		}
	},[input]);
	const [changing, setChanging] = React.useState(false);


	const options = {
		language: 'es',
	};

	// console.log({status})
	// if(status!=="loaded") return null;

	const [currentSearch, setCurrentSearch] = React.useState("");
	const [blured, setBlured] = React.useState(false);
	return (<div className="w-full">
		
		{(changing || !value?.formatted) ? <><StandaloneSearchBox
			// ref={autocomplete}
			className="w-full"
			onLoad={onLoad}
			options={options}
			google={{maps:{
				language:"es"
			}}}
			onPlacesChanged={onPlaceChanged}
		>
			<input
				className="bg-blue-light rounded-3xl px-5 py-2 w-full"
				ref={inputRef}
				autocomplete="one-time-code"
				onfocus="this.removeAttribute('readonly');"
				type="text"
				placeholder={label}
				onChange={(e)=>setCurrentSearch(e.target.value)}
				onBlur={()=>setBlured(true)}
				onFocus={()=>setBlured(false)}
				// value={value?.formatted}
				name="skdjnksd"
				// onChange={(e)=>setFormatted(e.target.value)}
			/>
		</StandaloneSearchBox>
		{blured && currentSearch.length>0 && <div  className="text-[red] text-xs">Por favor selecciona una dirección de la lista</div>}

		</>
			:
			<div className="relative w-full" >
				<input
					className="bg-blue-light rounded-3xl px-5 py-2 w-full"
					ref={inputRef}
					autocomplete="one-time-code"
					onfocus="this.removeAttribute('readonly');"
					type="text"
					placeholder={label}
					value={value?.formatted} />
				
				{!readOnly && <button onClick={()=>setChanging(true)} className="absolute right-2 top-1/2 -translate-y-1/2 w-5 h-5 bg-white rounded">x</button>}
				{/* {!readOnly && <Button color="primary" onClick={()=>setChanging(true)}>Cambiar</Button>}</div> */}

			</div>
		}
		{/* </LoadScriptWhenNeeded> */}
		{(location && !validLocation) && <Alert severity="error">Por favor ingresa una dirección valida</Alert>}

	</div>
	);
}
const Input = styled.input`
	box-sizing: border-box;
	border: 1px
	solid transparent;
	width: 100%;
	border-color: var(--fieldSetColorPrimary);
	line-height: 33px;
	border-radius: 4px;
	padding-left: 10px
;
`;