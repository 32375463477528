import React from "react";
import { Route, Redirect } from "react-router-dom";
import "./Routes.scss";
import loadable from "@loadable/component";
import {trackPage} from "analytics";
import Header from "components/Header/Header";


import { useQuery } from "react-query";
import {CLIENTE} from "api/TYPES";
import {crudFunctions, getCustomer, getUser} from "api";
import { createState, useState } from '@hookstate/core';
import {userStore, useUserStore} from 'api/store';
import Perfil from "pages/Login/Perfil";
import styled from "styled-components";
import Whatsapp from "assets/img/whatsApp.png";
import { CircularProgress } from "@material-ui/core";
const fallback = ()=> <CircularProgress />

const ConfirmarPago3D = loadable(() => import("../pages/ConfirmarPago3D"),fallback);
const Home = loadable(() => import("../pages/Home/Home"),fallback);
const NavBar = loadable(() => import("../components/NavBar/VNavBar"));
const Dedicado = loadable(() => import("../pages/Cotizador/Dedicado"),fallback);
const Consolidado = loadable(() => import("../pages/Cotizador/Consolidado"),fallback);
const TabsViajes = loadable(() => import("../pages/Viajes/TabsViajes"),fallback);
const Viaje = loadable(() => import("../pages/Viajes/Viaje"),fallback);
const TablaPagos = loadable(() => import("../pages/Pagos/TablaPagos"),fallback);
const Tracking = loadable(() => import("../pages/Tracking"),fallback);
const DatosIniciales = loadable(() => import("../pages/Login/DatosIniciales"),fallback);
const EnRevision = loadable(() => import("../pages/Login/EnRevision"),fallback);
const Configuracion = loadable(() => import("../pages/Configuracion/Configuracion"),fallback);
const CotizarSeguro = loadable(() => import("pages/Seguros/NuevoSeguro/NuevoSeguro"),fallback);
const TablaSeguros = loadable(() => import("pages/Seguros/TablaSeguros"),fallback);
const TablaTransportistas = loadable(() => import("pages/Transportistas/TablaTransportistas"),fallback);
const Transportista = loadable(() => import("pages/Transportistas/Transportista"),fallback);
const Seguro = loadable(() => import("pages/Seguros/Seguro"),fallback);
const FinalizarCuenta = loadable(() => import("pages/Login/FinalizarCuenta"),fallback);
const Plan = loadable(() => import("pages/Configuracion/Plan"),fallback);
const ValidarViaje = loadable(() => import("pages/ValidarViaje/ValidarViaje"),fallback);

const Activar = loadable(() => import("../pages/Login/Activar"),fallback);
const Login = loadable(() => import("../pages/Login/Login"),fallback);
const Registro = loadable(() => import("../pages/Login/Registro"),fallback);
const Forgot = loadable(() => import("../pages/Login/Olvide"),fallback);
const Reset = loadable(() => import("../pages/Login/Reset"),fallback);
const PrivateRoute = loadable(() => import("./PrivateRoute/PrivateRoute"));
const PublicRoute = loadable(() => import("./PublicRoute/PublicRoute"));

const Float = styled.a`
	display:flex;
	align-items:center;
	justify-content:center;
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
`;

 
const Routes = () => {
	const {data:user, status:s2} = useQuery(CLIENTE.name, getCustomer);

	// const {user} = useUserStore();
	const state = useState(userStore);
	const status = state.status.get();
	const loggedIn = state.loggedIn.get();
	// const user = state.user.get();
	
	React.useEffect(()=>{
		let path = window.location.pathname;
		const myregexp = /[0-9a-fA-F]{24}/g;
		path = path.replace(myregexp, "id");
		trackPage(path);
	},[window.location.pathname]);

	let RNavBar = window.location.pathname === "/dedicado"||window.location.pathname === "/login" || window.location.pathname === "/registro" || window.location.pathname.startsWith("/reset") || window.location.pathname.startsWith("/reset") || window.location.pathname === "/forgot" ? null : <><NavBar cliente={user} /></>;

	if (status==="loading")return <>Loading</>;
	// if (status==="error")return <>Error</>;
	// if (status==="error"){
	// 	return <>
	// 		<PublicRoute user={user} exact path="/login" component={Login} />
	// 		<PublicRoute user={user} exact path="/registro" component={Registro} />
	// 		<PublicRoute user={user} exact path="/forgot" component={Forgot} />
	// 		<PublicRoute user={user} exact path="/reset/:token/:idNutriologo" component={Reset} />
	// 	</>;
	// }
	if(user?.cliente?.status==="pendiente")return <>
		<Redirect to={{ pathname: "/pendiente-documentos" }} />
		{RNavBar}
		<div className="container">
			<DatosIniciales />
		</div>
	</>;
	else if(user?.cliente?.status==="en_revision")return <>
		<Redirect to={{ pathname: "/en-revision" }} />
		{RNavBar}
		<div className="container">
			<EnRevision />
		</div>
	</>;
	return (
		<>
			{RNavBar}
			<Float href="https://api.whatsapp.com/send?phone=5215563160030" target="_blank">
				<img src={Whatsapp} style={{width:40,height:40,color:'white'}} />
			</Float>
			<div>
				<Route status={status} loggedIn={loggedIn} exact path="/validar-viaje/:id" component={ValidarViaje} />
				<PublicRoute status={status} loggedIn={loggedIn} exact path="/login" component={Login} />
				<PublicRoute status={status} loggedIn={loggedIn} exact path="/registro" component={Registro} />
				<PublicRoute status={status} loggedIn={loggedIn} exact path="/forgot" component={Forgot} />
				<PublicRoute status={status} loggedIn={loggedIn} exact path="/reset/:token/:id" component={Reset} />
				<PublicRoute status={status} loggedIn={loggedIn} exact path="/activar/:token/:idUsuario" component={Activar} />
				<PublicRoute status={status} loggedIn={loggedIn} path="/asignar-password" component={FinalizarCuenta} />
				
				<Route status={status} loggedIn={loggedIn} path="/nuevo-seguro" component={CotizarSeguro} />
				<Route status={status} loggedIn={loggedIn} path="/pago-confirmado" component={ConfirmarPago3D} />

				<div className={(window.location.pathname === "/login" || window.location.pathname === "/registro"||window.location.pathname === "/forgot") ? "": "container-app"}>
					
					<PrivateRoute status={status} loggedIn={loggedIn} exact path="/cotizar-seguro" component={CotizarSeguro} />
					<PrivateRoute status={status} loggedIn={loggedIn} exact path="/seguros/:id" component={Seguro} />
					<PrivateRoute status={status} loggedIn={loggedIn} exact path="/seguros" component={TablaSeguros} />
					<PrivateRoute status={status} loggedIn={loggedIn} exact path="/" component={Home} />
					<PrivateRoute status={status} loggedIn={loggedIn} path="/dedicado" component={Dedicado} />
					<PrivateRoute status={status} loggedIn={loggedIn} path="/consolidado" component={Consolidado} />
					<PrivateRoute status={status} exact loggedIn={loggedIn} path="/viajes/:seccion?" component={TabsViajes} />
					<PrivateRoute status={status} exact loggedIn={loggedIn} path="/viaje/:id/:seccion?" component={Viaje} />
					<PrivateRoute status={status} loggedIn={loggedIn} path="/pagos" component={TablaPagos} />
					<PrivateRoute status={status} loggedIn={loggedIn} path="/tracking" component={Tracking} />
					<PrivateRoute status={status} loggedIn={loggedIn} path="/configuracion/:seccion?/:empresa?" component={Configuracion} />
					<PrivateRoute status={status} loggedIn={loggedIn} path="/perfil" component={Perfil} />
					<PrivateRoute status={status} loggedIn={loggedIn} path="/plan" component={Plan} />
					
					<PrivateRoute exact status={status} loggedIn={loggedIn} path="/transportistas" component={TablaTransportistas} />
					<PrivateRoute loggedIn={loggedIn} path="/transportistas/:id/:seccion?" component={Transportista} />

				</div>
			</div>
		</>
	);
	
	
};

export default Routes;
