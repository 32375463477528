import React from "react";
import PropTypes from "prop-types";
import {reportError} from "api";
import {serializeError} from "serializeError";

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
		reportError({...serializeError(error),url:document.URL});
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// También puedes registrar el error en un servicio de reporte de errores
		// logErrorToMyService(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <div style={{textAlign:"center"}}>
				<img src={require("assets/img/logo/logo_sin_texto.png")}
					style={{ 
						width: "100px",
						objectFit: "cover",
						margin:"auto",
						display:"block",
						marginTop:"50px",
					
					}}
				/>
				{/* <img src={require("assets/img/error.png")}
					style={{ 
						width: "35vw",
						objectFit: "cover",
						marginTop:"50px",
						marginBottom:"40px"
					}}
				/> */}
				<h1>Ocurrió un error inesperado</h1>
				<h2>Por favor actualiza la página</h2>
			</div>
		}

		return this.props.children; 
	}
}