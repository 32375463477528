
import React from "react";
import { Button } from "@material-ui/core";
import TextArea from "../Text/Text";
import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";

export default function MultiText (props) {
	const addRow = () => {
		props.onChange([...props.value||[], ""], props.name);
	};
	const removeRow = (idx) => {
		props.onChange(props.value.filter((row,i)=>{
			if(i===idx) return false;
			return true;
		}), props.name);
	};
	const handleChange = (value, idx) => {
		props.onChange(props.value.map((row,i)=>{
			if(i===idx) return value;
			return row;
		}), props.name);
	};
	return (<>
		{props.value?.map((row, idx) => (
			<>
				<div style={{display:"flex",marginBottom:"10px", alignItems:"center"}}>
					<TextArea
						type="text"
						value={row}
						placeholder="Escribe aquí"
						onChange={value => {
							handleChange(value,idx);
						}}
						className="text-area"
					/>
					<ClearIcon onClick={() =>removeRow(idx)} fontSize="small" style={{color:"red"}} />

					
				</div>
			</>
		))}
		<Button variant="contained" style={{backgroundColor:"#2D3C3A",color:"white"}} onClick={addRow} >
      Añadir
		</Button>
	</>);
}

MultiText.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
	name: PropTypes.string,
};