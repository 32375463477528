import React from "react";
import PropTypes from "prop-types";
import BaseCell from "../BaseCell/BaseCell";
const currency = require("currency.js");

export default function TextCell({cell}){
	let formatted = cell.value;
	if(cell.column.format == "currency"){
		formatted = currency(cell.value).format();
	}
	return (
		<>
			<BaseCell>{cell.column.prepend || ""}{formatted}</BaseCell>
		</>
	);
}

TextCell.propTypes = {
	cell: PropTypes.object
};