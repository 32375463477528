import React from "react";
import { FixedSizeList as List } from "react-window";

const OPTION_HEIGHT = 40;
const ROWS = 6;

export default function App(props) {
	const {children, options, getValue, selectProps, setValue} = props;
	// const sizes = React.useMemo(
	// 	() => {
	// 		return children.map(c=>30 * Math.ceil(c.props.children.length/22))
	// 	},
	// 	[children]
	// );
	// const listRef = React.useRef();
	// const sizeMap = React.useRef({});
	// const setSize = React.useCallback((index, size) => {
	// 	sizeMap.current = { ...sizeMap.current, [index]: size };
	// 	listRef.current.resetAfterIndex(index);
	// }, []);
	// const getSize = index => sizes?.[index] || 500;
	// const [windowWidth] = useWindowResize();

	return (
		<List
			ref={selectProps.listRef}
			height={300}
			// width="100%"
			itemCount={children.length}
			itemSize={40}
			// itemData={data}
		>
			{({ data, index, style }) => (
				<div style={style}>
					{children[index]}
					{/* <Row
						data={data}
						options={options}
						index={index}
						setSize={setSize}
						windowWidth={windowWidth}
						onClick={setValue}
					/> */}
				</div>
			)}
		</List>
	);
}
const Row = (props) => {
	const { data, index, setSize, windowWidth, onClick, options } = props;

	const rowRef = React.useRef();

	React.useEffect(() => {
		setSize(index, rowRef.current.getBoundingClientRect().height);
	}, [setSize, index, windowWidth]);

	return (
		<div
			onClick={()=>onClick(options[index])}
			ref={rowRef}

		>
			{data[index]}
		</div>
	);
};

export const useWindowResize = () => {
	const [size, setSize] = React.useState([0, 0]);

	React.useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}

		window.addEventListener("resize", updateSize);
		updateSize();

		return () => window.removeEventListener("resize", updateSize);
	}, []);

	return size;
};

// const MenuList = (props) => {
// 	const {
// 		options,
// 		children,
// 		getValue
// 	} = props;
// 	console.log({props})
// 	const [value] = getValue();
// 	const initialOffset =
// 		options.indexOf(value) !== -1
// 			? Array.isArray(children) &&
// 				children.length >= ROWS
// 				? options.indexOf(value) >= ROWS
// 					? options.indexOf(value) *
// 							OPTION_HEIGHT -
// 						OPTION_HEIGHT * 5
// 					: 0
// 				: 0
// 			: 0;

// 	return Array.isArray(children) ? (
// 		<List
// 			height={
// 				children.length >= ROWS
// 					? OPTION_HEIGHT * ROWS
// 					: children.length * OPTION_HEIGHT
// 			}
// 			itemCount={children.length}
// 			itemSize={OPTION_HEIGHT}
// 			initialScrollOffset={initialOffset}
// 		>
// 			{({ style, index }) => {
// 				return (
// 					<div style={style}>
// 						{children[index]}
// 					</div>
// 				);
// 			}}
// 		</List>
// 	) : (
// 		<div>{children}</div>
// 	);
// };

// export default MenuList;