import React from "react";
import { Button, Icon } from "@material-ui/core";
import swal from "sweetalert";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";

import Theme from "../../Theme";

import "./DefaultSwal.scss";

export default function PushNotificationModal(props) {
	const TipoModal = {
		success: "checkIcon",
		error: "Error",
		advertencia: "Estas seguro?"
	};
	return (
		<>
			<ThemeProvider theme={Theme}>
				<div>
					<Icon>{TipoModal[props.tipo]}</Icon>
					<h1>{props.titulo}</h1>
					{/* <h5>{props.subtitulo}</h5> */}
					{props.subtitulo.includes("\n") ? props.subtitulo.split("\n").map((text, i)=>(<h5 key={i}>{text}</h5>)) : <h5>{props.subtitulo}</h5>}
					<div className="buttons-sweet-alert">
						{props.actions?.map(action=>
							<>
								{action.tipo === "link" &&
									<Button 
										variant="contained" 
										color="primary" 
										key={action.titulo} 
										onClick={()=>{
											window.location.href = action.to;
											swal.close();
										}}>
										Aceptar
									</Button>
								}
								{action.tipo === "fn" &&
									<Button 
										variant="contained" 
										color="primary" 
										key={action.titulo} 
										onClick={()=>{
											action.fn();
											swal.close();
										}}>
										Aceptar
									</Button>
								}
							</>
						)}
						<Button variant="contained" color="secondary" onClick={() => {swal.close();}}>Cerrar</Button>
					</div>
				</div>
			</ThemeProvider>
		</>
	);
}

PushNotificationModal.propTypes = {
	titulo: PropTypes.string,
	subttitulo: PropTypes.string,
	actions: PropTypes.array,
	tipo: PropTypes.string
};